<template>
  <q-dialog
    ref="dialogRef"
    position="top"
    square
    @hide="onHide"
  >
    <q-card class="q-dialog-plugin">
      <q-input
        ref="input"
        v-model="search"
        :loading="status == 'pending'"
        debounce="400"
        placeholder="Start typing to search KYGUNCO"
        filled
        square
        autofocus
        clearable
        @keyup.enter="navigate"
        @clear="onClear"
        @focus="onFocus"
      >
        <template #prepend>
          <q-icon :name="mdiMagnify" />
        </template>
      </q-input>

      <q-slide-transition
        style="max-height: 70vh"
        class="scroll"
      >
        <div v-if="search?.length">
          <div v-if="total">
            <q-item v-if="suggestions.length">
              <q-item-section>
                <div class="row items-center q-gutter-sm">
                  <div class="text-subtitle2">
                    Popular terms:
                  </div>
                  <div
                    v-for="suggestion in suggestions"
                    :key="suggestion.id"
                  >
                    <q-chip
                      :label="suggestion.q"
                      :title="suggestion.q"
                      color="white"
                      class="no-margin"
                      style="max-width: 300px;"
                      outline
                      clickable
                      @click="search = suggestion.q"
                    />
                  </div>
                </div>
              </q-item-section>
            </q-item>

            <q-separator v-if="suggestions.length" />

            <LazySearchItemProduct
              v-for="item in data?.results?.[1]?.hits ?? []"
              :key="item.document.slug"
              :item="item"
            />

            <LazySearchItemCategory
              v-for="item in data?.results?.[2]?.hits ?? []"
              :key="item.document.slug"
              :item="item"
            />

            <LazySearchItemBrand
              v-for="item in data?.results?.[3]?.hits ?? []"
              :key="item.document.slug"
              :item="item"
            />

            <LazySearchItemArticle
              v-for="item in data?.results?.[4]?.hits ?? []"
              :key="item.document.slug"
              :item="item"
            />
          </div>

          <q-item
            v-else-if="status != 'pending'"
            class="bg-negative"
          >
            <q-item-section>
              We couldn't find anything that matches your query.
            </q-item-section>
            <q-item-section side>
              <q-btn
                label="Clear"
                flat
                @click="clear"
              />
            </q-item-section>
          </q-item>
        </div>
      </q-slide-transition>
    </q-card>
  </q-dialog>
</template>

<script lang="ts" setup>
import { mdiMagnify } from '@quasar/extras/mdi-v7';
import { QInput, useDialogPluginComponent } from 'quasar';
import { Order, buildFieldsList, buildFilterBy, buildSortBy } from 'typesense-utils';
import {
  LazySearchItemBrand,
  LazySearchItemCategory,
  LazySearchItemArticle,
  LazySearchItemProduct,
} from '#components';
import type { Article, Brand, Category, Product, Query } from '~/types/search';
import type {
  MultiSearchRequestSchema,
  MultiSearchResponse,
} from '~/types/typesense';

type MultiSearchResponseType = MultiSearchResponse<[Query, Product, Category, Brand, Article]>;

defineEmits([...useDialogPluginComponent.emits]);

const { dialogRef, onDialogHide } = useDialogPluginComponent();
const route = useRoute();
const { $bus, $typesense } = useNuxtApp();

const input = ref<QInput | null>();

const search = ref<string | null>((route.query.q as string) ?? null);

const searches = computed<MultiSearchRequestSchema[]>(
  () => ([
    {
      q: search.value?.length ? search.value : '*',
      per_page: 5,
      query_by: buildFieldsList<Query>(['q']),
      include_fields: buildFieldsList<Query>(['q']),
      highlight_fields: 'none',
      sort_by: buildSortBy<Query>({ count: { $order: Order.Desc } }),
      filter_by: search.value?.length && search.value != '*' ? buildFilterBy<Query>({ q: { $ne: search.value } }) : '',
      collection: 'kygunco_product_queries',
      use_cache: true,
    },
    {
      q: search.value?.length ? search.value : '*',
      per_page: 5,
      query_by: buildFieldsList<Product>(['name', 'sku', 'mpn', 'style', 'upc', 'keywords']),
      include_fields: buildFieldsList<Product>([
        'id',
        'name',
        'slug',
        'sku',
        'mpn',
        'upc',
        'price',
        'thumbnailUrl',
      ]),
      highlight_fields: buildFieldsList<Product>([
        'name',
        'sku',
        'mpn',
        'upc',
      ]),
      highlight_full_fields: buildFieldsList<Product>([
        'name',
        'sku',
        'mpn',
        'upc',
      ]),
      collection: 'kygunco_products',
      enable_analytics: !!search.value && search.value.length >= 3,
    },
    {
      q: search.value?.length ? search.value : '*',
      per_page: 5,
      query_by: buildFieldsList<Category>([
        'name',
        'slug',
        'path',
        'description',
        'metaTitle',
        'metaDescription',
      ]),
      include_fields: buildFieldsList<Category>([
        'name',
        'slug',
        'path',
        'imageUrl',
      ]),
      highlight_fields: buildFieldsList<Category>(['name', 'path']),
      highlight_full_fields: buildFieldsList<Category>(['name', 'path']),
      collection: 'kygunco_categories',
    },
    {
      q: search.value?.length ? search.value : '*',
      per_page: 5,
      query_by: buildFieldsList<Brand>([
        'name',
        'slug',
        'description',
        'metaTitle',
        'metaDescription',
      ]),
      include_fields: buildFieldsList<Brand>(['name', 'slug', 'imageUrl']),
      highlight_fields: buildFieldsList<Brand>(['name']),
      highlight_full_fields: buildFieldsList<Brand>(['name']),
      collection: 'kygunco_brands',
    },
    {
      q: search.value?.length ? search.value : '*',
      per_page: 5,
      query_by: buildFieldsList<Article>(['title', 'slug', 'content']),
      include_fields: buildFieldsList<Article>([
        'title',
        'slug',
        'publishedAt',
        'thumbnailUrl',
      ]),
      highlight_fields: buildFieldsList<Article>(['title']),
      highlight_full_fields: buildFieldsList<Article>(['title']),
      collection: 'kygunco_articles',
    },
  ]),
);

const { data, refresh, status, clear } = useAsyncData('global_search',
  () => $typesense.fetch<MultiSearchResponseType>('multi_search', {
    method: 'POST',
    body: { searches: searches.value },
  }),
  {
    immediate: !!search.value,
    server: false,
    lazy: true,
  },
);

const total = computed(
  () =>
    data.value?.results
      ?.map(r => r.hits?.length ?? 0)
      .reduce((a, b) => a + b, 0) ?? 0,
);

const suggestions = computed(() =>
  (data.value?.results[0].hits ?? [])
    .map(h => h.document)
    .filter(d => d.q.length && d.q != search.value));

const navigate = () => {
  if (!search.value?.length) {
    return;
  }

  $bus.emit('catalog:search', search.value);

  return navigateTo({ path: '/search', query: { q: search.value } });
};

watch(search, value => !value?.length || refresh());

const onHide = () => {
  clear();
  onDialogHide();
};

const onClear = () => {
  search.value = null;
  input.value?.focus();
};

const onFocus = () => input.value?.select();
</script>
